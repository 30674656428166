import React from "react";
import { Box } from "@chakra-ui/react";

import Hero from "../views/Philosophy/Hero";
import PhilosophyView from "../views/common/Philosophy";
import FooterSpace from "../views/Philosophy/FooterSpace";

function Philosophy() {
  return (
    <Box>
      <Hero />
      <PhilosophyView desktopHeight="viewHomeSafetyMore" />
      <FooterSpace />
    </Box>
  );
}

export default Philosophy;
