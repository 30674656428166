import React from "react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import PageHero from "../../../layout/PageHero";
import imgHero from "../../../images/heros/pic3.jpg";

function Hero() {
  const { t } = useTranslation();
  return (
    <PageHero
      backgroundImage={imgHero}
      titleBold={t("page@philosophy@hero@title")}
      isLarge={true}
      smallOnMobileAm={true}
    >
      {/* {t("page@philosophy@hero@text")} */}
    </PageHero>
  );
}

export default Hero;
