import React from "react";
import { Box } from "@chakra-ui/react";

function FooterSpace() {
  return (
    <Box minHeight="6xl" />
  );
}

export default FooterSpace;
